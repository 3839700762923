import 'tinymce'
import media  from './inc/media.js'
import mask  from './inc/mask.js'
//import modal  from './inc/modal.js'

// Select2
import 'select2'

// Popper
import'@popperjs/core'

// JQuery Mask PLugin
import'jquery-mask-plugin'

// Create a loading element
var loading = ''
loading+= '<div class="e-loading">'
    loading+= '<i class="fas fa-circle-notch fa-spin fa-fw"></i>'
loading+= '</div><!--/.e-loading -->'

$(() => {
    mask.inputs();

    /**
     * --------------------------------------------------------------------------
     * FORM MODAL
     * --------------------------------------------------------------------------
     *
     * Show a modal when submit form.
     *
     */
    var formModal = document.getElementById('formModal')
    if(formModal) {
        formModal = new bootstrap.Modal(formModal)
        formModal.show();
    }

    /**
     * --------------------------------------------------------------------------
     * FIXED HEADER
     * --------------------------------------------------------------------------
     *
     */
    var site = document.getElementById('site')
    window.onscroll = function(e) {
        if(document.documentElement.scrollTop > 10) {
            site.classList.add('scroll')
        } else {
            site.classList.remove('scroll')
        }
    }

    /**
     * ITEM MODAL
     */
    $('tr[data-action="modal"] td:not(:last-child').on('click', function(){
        var route = $(this).parent().attr('data-content')

        $('#itemModal .modal-content').html(loading)
        $.ajax({
            url: route,
            method: 'PUT',
            context: document.body
        }).done(function(data) {
            $('#itemModal .modal-content').html(data)
        });

        var myModal = new bootstrap.Modal(document.getElementById('itemModal'), {})
        myModal.show()
    });

    /**
     * CHECKOUT TABS
     */
    $('.nav-checkout .tab-pane:not(.active)').find('input').each(function() {
        $(this).removeAttr('required')
    })

    $('.nav-checkout .nav-link').on('click', function() {
        var target = $(this).attr('data-bs-target')

        $('.nav-checkout input').removeAttr('required')
        $('.nav-checkout '+ target +' input').attr('required','required')
    })

    /**
     * MEDIA
     */
     media.mediaModal(loading)
     media.uploadToggle(true)
     media.removeButtom($('.uploaded'))
     media.sendItem()

    /**
     * Page Modal
     */
    $('[data-toggle="modal"]').on('click', function() {
        var route = $(this).attr('data-content')
        var modal = $('#pageModal')

        $('#pageModal .modal-body').html(loading)

        $.ajax({
            url: route,
            method: 'PUT',
            context: document.body
        }).done((data) => {
            modal.find('.modal-content').html(data)
        })

        var myModal = new bootstrap.Modal(document.getElementById('pageModal'), {})
        myModal.show()
    })

    /**
     * ALERT MODAL
     */
    var alertModal = document.getElementById('alertModal')
    if(alertModal) {
        alertModal = new bootstrap.Modal(alertModal, {})
        alertModal.show()
    }

    /**
     * EXTERNAL LINKS
     * Remember to add the icon trougth CSS
     */
    $('body').find('a[href*="//"]').each(function() {

        // Don't run if is from the same domain.
        var a = new RegExp('/' + window.location.host + '/');
        if (a.test(this.href) || $(this).hasClass('skip-external')) {
            return true;
        }

        // Add external rel attribute
        $(this).attr('rel', 'external');
        $(this).attr('title', 'Site externo');
        // Check if has text element
        if ($(this).find('.text') && $(this).find('.text').length > 0) {
            $(this).addClass('skip-withtext');
        }
    });
    //--- external-link

    /**
     * SELECT 2
     */
    $('select').select2({
        language: 'pt-BR',
        selectOnClose: true
    });
    $('.select2-add').select2({
        language: 'pt-BR',
        tags: true,
        selectOnClose: true
    });

    // --------------------------------------------------------------------------------------------

    /**
     * Sale product select
     */
    var productItem = ''
    productItem+= ''
    productItem+= '<tr id="productItem##ID##" data-id="##ID##">'
    productItem+= '<td>'
    productItem+= '<span id="productName##ID##">##NAME##</span>'
    productItem+= '<input type="hidden" name="sale_product[##I##][id]" value="##ID##">'
    productItem+= '</td>'
    productItem+= '<td>'
    productItem+= '<div class="input-group input-group-sm">'
        productItem+= '<button class="btn btn-sm btn-outline-st" type="button" data-toggle="changeQuantity" data-action="qtdRemove">'
            productItem+= '<i class="fas fa-fw fa-minus"></i>'
            productItem+= '<span class="sr-only">Remover</span>'
        productItem+= '</button>'
        productItem+= '<input type="number" class="form-control form-control-small" id="productQtd##ID##" name="sale_product[##I##][qtd]" value="1">'
        productItem+= '<button class="btn btn-sm btn-outline-st" type="button" data-toggle="changeQuantity" data-action="qtdAdd">'
            productItem+= '<i class="fas fa-fw fa-plus"></i>'
            productItem+= '<span class="sr-only">Adicionar</span>'
        productItem+= '</button>'
        productItem+= '</div>'
    productItem+= '</td>'
    productItem+= '<td><input type="text" class="form-control form-control-sm form-control-small" id="productValue##ID##" name="sale_product[##I##][value]" value="##MONEY##"></td>'
    //productItem+= '<td>##MONEY##</td>'
    productItem+= '<td><span class="qtdTotal">##MONEY##</td>'
    productItem+= '</tr>'
    productItem+= '</table>'

    var options = {minimumFractionDigits: 2, maximumFractionDigits: 2}
    var formater = new Intl.NumberFormat('pt-BR', options)

    checkQuantity();
    $('#field_sale_product').on('change', function(e) {
        var i = $(this).val()
        var count = parseInt($('[id*="productItem"]').length);
        var products = JSON.parse($(this).attr('data-product'))

        if(!products[i]) return

        $(this).attr('data-count', count + 1)

        var item = productItem
        item = item.replaceAll('##ID##', products[i].id)
        item = item.replaceAll('##NAME##', products[i].name)
        item = item.replaceAll('##VALUE##', products[i].val)
        item = item.replaceAll('##MONEY##', products[i].money)
        item = item.replaceAll('##I##', count)

        var total = parseFloat($('#total_value').attr('data-value'))
        total = (total + parseFloat(products[i].val))
        var money = formater.format(total)

        if(!$('#productItem'+ products[i].id).length) {

            $('#total_value').attr('data-value', total).html(money).trigger('change')
            $('#product_list').append(item)

            changeQuantity(products[i].id)

        } else {
            calcQuantity(products[i].id, 'add')
        }

        $(this).val(0).trigger('change')
    })

    function calcQuantity(id, calc = false) {
        var total = parseFloat($('#total_value').attr('data-value'))

        var name = $('#productName'+ id).html()
        var value = parseFloat($('#productValue'+ id).val().replace(',','.'))
        var input = $('#productQtd'+ id)

        var qtd = parseInt(input.val())

        if(calc == 'add') {
            qtd++

            $('#total_value').attr('data-value', total + value).html(formater.format(total + value)).trigger('change')
        }

        else if(calc == 'remove') {
            qtd--

            if(qtd == 0 && window.confirm('Deseja remover '+ name +'?')) {
                $('#productItem'+ id).remove()
            } else if ( qtd == 0 ) {
                return
            }

            $('#total_value').attr('data-value', total - value).html(formater.format(total - value)).trigger('change')
        }

        else {
            var totalValue = 0
            $('#product_list').find('[id*="productValue"]').each( function() {
                var currentQtd = parseFloat($('#productQtd'+ id).val())

                if(currentQtd < 1 && window.confirm('Deseja remover '+ name +'?')) {
                    $(this).parents('[id*=productItem]').remove()
                } else if ( currentQtd == 0 ) {
                    return
                }

                console.log(value, currentQtd, totalValue)
                totalValue = totalValue + (value * currentQtd)
            })

            $('#total_value').attr('data-value', totalValue).html(formater.format(totalValue)).trigger('change')
        }

        input.val(qtd)
        $('#productItem'+ id).find('.btn-qtd').html(qtd)
        $('#productItem'+ id).find('.qtdTotal').html(formater.format(value * qtd))

    }

    function checkQuantity() {
        $('[id*="productItem"]').each(function() {
            var id = $(this).attr('data-id')

                console.log(id)
            $(this).find('[data-action="qtdAdd"]').on('click', function() {
                calcQuantity(id, 'add')
            })
            $(this).find('[id*=productQtd], [id*=productValue]').on('keypress', function(e) {
                var key = e.which || e.keyCode;
                if (key == 13) {
                    e.stopPropagation()
                    e.preventDefault()

                    calcQuantity(id)
                }
            })
            $(this).find('[id*=productQtd], [id*=productValue]').on('change', function() {
                calcQuantity(id)
            })
            $(this).find('[data-action="qtdRemove"]').on('click', function() {
                calcQuantity(id, 'remove')
            })

        })
    }

    function changeQuantity(id) {
        $('#productItem'+ id +' [data-action="qtdAdd"]').on('click', function() {
            calcQuantity(id, 'add')
        })
        $('#productItem'+ id +' #productQtd'+ id +', #productItem'+ id +' #productValue'+ id).on('keypress', function(e) {
            var key = e.which || e.keyCode;
            if (key == 13) {
                e.stopPropagation()
                e.preventDefault()

                calcQuantity(id)
            }
        })
        $('#productItem'+ id +' #productQtd'+ id +', #productItem'+ id +' #productValue'+ id).on('change', function() {
            calcQuantity(id)
        })
        $('#productItem'+ id +' [data-action="qtdRemove"]').on('click', function() {
            calcQuantity(id, 'remove')
        })
    }

    function changeDots(value)
    {
        value = value.replace('.','')
        value = value.replace(',','.')

        return parseFloat(value)
    }

    $('[data-toggle="calcPercent"]').on('blur', function() {
        var percent = changeDots($(this).val())
        var target = $(this).attr('data-target')
        var total = parseFloat($('#total_value').attr('data-value'))

        if(!percent || !total) {
            $(this).val('')
            $('#discount_value').attr('data-value', 0).html(formater.format(0)).trigger('change')
            $('#'+ target).val('')
            $('#total_value').trigger('change')
            return
        }

        if(percent > 100) {
            $(this).val('100').trigger('change')
            return
        }

        total = (total / 100) * percent;
        var money = formater.format(total)

        $('#discount_value').attr('data-value', total).html(money).trigger('change')
        $('#total_value').trigger('change')

        $('#'+ target).val(money)
    })

    $('[data-toggle="calcDiscount"]').on('change', function() {
        var discount = changeDots($(this).val())
        var total = parseFloat($('#total_value').attr('data-value'))
        var localFormater = new Intl.NumberFormat('pt-BR', {maximumFractionDigits: 2})

        if(!discount || !total) {
            $(this).val('')
            $('#discount_value').attr('data-value', 0).html(formater.format(0)).trigger('change')
            $('[data-toggle="calcPercent"]').val('')
            $('#total_value').trigger('change')
            return
        }

        if(discount > total) {
            $(this).val(formater.format(total)).trigger('change')
            return
        }

        total = (discount * 100) / total;
        var money = formater.format(discount)

        $('#discount_value').attr('data-value', discount).html(money).trigger('change')
        $('#total_value').trigger('change')

        $('[data-toggle="calcPercent"]').val(localFormater.format(total))
    })

    $('.calc-shipping').on('change', function() {
        var shipping = changeDots($(this).val())
        var money = formater.format(shipping)

        $('#shipping_value').attr('data-value', shipping).html(money).trigger('change')
        $('#total_value').trigger('change')
    })

    $('#total_value').on('change', function() {
        var total = parseFloat($('#total_value').attr('data-value'))
        var discount = parseFloat($('#discount_value').attr('data-value'))
        var shipping = parseFloat($('#shipping_value').attr('data-value'))

        var final = total - discount + shipping;
        var money = formater.format(final)

        $('#final_value').attr('data-value', final).html(money).trigger('change')
        $('[name="sale_value"]').val(final)
    })

    $('#discount_value').on('change', function() {
        var value = parseFloat($(this).attr('data-value'))

        if(value > 0) {
            $('#total_value').parent().addClass('del')
            $(this).parent().addClass('text-success').css('font-weight', 'bold')
        } else {
            $('#total_value').parent().removeClass('del')
            $(this).parent().removeClass('text-success').css('font-weight', 'normal')
        }
    })

    // --------------------------------------------------------------------------------------------

    /**
     * Sale ingredient select
     */
    var ingredientItem = '';
    ingredientItem+= '';
    ingredientItem+= '<tr id="ingredientItem##ID##">';
    ingredientItem+= '<td>';
    ingredientItem+= '<span id="ingredientName##ID##">##NAME##</span>';
    ingredientItem+= '<input type="hidden" name="recipe_ingredient[##I##][id]" value="##ID##">';
    ingredientItem+= '</td>';
    ingredientItem+= '<td>';
        ingredientItem+= '<input type="number" class="form-control form-control-sm" id="field_recipe_ingredient" name="recipe_ingredient[##I##][qtd]" value="1">';
    ingredientItem+= '</td>';
    ingredientItem+= '<td>';
        ingredientItem+= '<button type="button" class="btn btn-outline-st btn-sm btn-remove">';
        ingredientItem+= '<i class="fas fa-fw fa-trash-alt"></i>';
        ingredientItem+= '<span class="sr-only"></span>';
        ingredientItem+= '</button>';
    ingredientItem+= '</td>';
    ingredientItem+= '</tr>';
    ingredientItem+= '</table>';

    $('#field_recipe_ingredient').on('change', function(e) {
        var i = $(this).val()
        var count = parseInt($(this).attr('data-count'));
        var ingredients = JSON.parse($(this).attr('data-ingredient'))

        if(!ingredients[i]) return

        $(this).attr('data-count', count + 1)

        var item = ingredientItem
        item = item.replaceAll('##ID##', ingredients[i].id)
        item = item.replaceAll('##NAME##', ingredients[i].name)
        item = item.replaceAll('##I##', count)

        if(!$('#ingredientItem'+ ingredients[i].id).length) {
            $('#ingredient_list').append(item)
            enableButton(i)
        }

         $(this).val(0).trigger('change')
    })

    $('.btn-remove').on('click', function(e) {
        e.stopPropagation
        e.preventDefault

        $(this).parents('tr').remove()
    })

    function enableButton(id) {
        $('#ingredientItem'+ id +' .btn-remove').on('click', function(e) {
            e.stopPropagation
            e.preventDefault

            $('#ingredientItem'+ id).remove()
        })
    }

    if ($('#field_role_base').val() == 4) {
        $('#form-client').css('display','block')
    }

    $('#field_role_base').on('change', function(e) {
        var val = $(this).val();
        if(val == 4) {
            $('#form-client').css('display','block')
        } else {
            $('#form-client').css('display','none')
        }
    })

    //--- select-2

    /**
     * SEARCH TABLE
     */
    $('input[type="search"]').on("keyup", function() {
        var value = $(this).val().toLowerCase();
        $(".table tbody tr").filter(function() {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
    });
    //--- search-table

    /**
     * SORT TABLE
     */
    function sortTable(th)
    {
        if(th.is(':last-child')) return;

        var table = th.parents('.table').eq(0);
        var rows = table.find('tbody tr').toArray().sort(comparer(th.index()));

        th.siblings().removeAttr('data-sort');

        if(th.attr('data-sort') != 'asc') {
            th.attr('data-sort', 'asc');
        } else {
            th.attr('data-sort', 'desc');
        }

        if (th.attr('data-sort') != 'asc') rows = rows.reverse()
        for (var i = 0; i < rows.length; i++) table.append(rows[i])
    }

    function comparer(index) {
        return function(a, b) {
            var valA = getCellValue(a, index), valB = getCellValue(b, index);
            return $.isNumeric(valA) && $.isNumeric(valB) ? valA - valB : valA.toString().localeCompare(valB);
        }
    }

    function getCellValue(row, index){
        return $(row).children('td').eq(index).text();
    }

    sortTable($('thead th[data-sort]'));

    $('thead th').on('click', function() {
        sortTable($(this));
    });
    //--- sort-table

    /**
     * CLONE ITEM
     */
    $('[data-toggle="addText"]').on('click', function(e) {
        e.stopPropagation;
        e.preventDefault;

        var target = $(this).attr('data-target');
        var container = $(target).parents('.form-group');
        $(target).clone().appendTo(container);
        container.find('input').last().val('').addClass('form-control-copy');
        mask.inputs();

        return false;
    });

    $('.form-control-copy').on('change', function() {
        console.log($(this));
        if(!$(this).val()) {
            $(this).remove();
        }
    });
    //--- colone-item

    /**
     * PRINT
     */
    $('a[href="#print"]').on('click', (e) => {
        e.stopPropagation;
        e.preventDefault;

        window.print();
    });
    //--- print

    /**
     * TOOTIP
     */
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[title]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    })
    //--- print

    /**
     * GET ADDRESS
     * Get all address data by CEP
     */
    function updateCep(cep) {
        $('#field_address_address').val('...');
        $('#field_address_district').val('...');
        $('#field_address_city').val('...');
        $('#field_address_state').val('...');
        $('#field_address_country').val('...');

        $.getJSON('https://viacep.com.br/ws/'+ cep + '/json/', function(data){
            $('#field_address_street').val(data.logradouro);
            $('#field_address_neighborhood').val(data.bairro);
            $('#field_address_city').val(data.localidade);
            $('#field_address_state').val(data.uf);
        });
    }

    $('#field_address_zip').on('change', function(){
        console.log($(this));
        updateCep($(this).val());
    });
    //--- get-address

    /**
     * MULTIPLE AUTHOR
     */
    $('[data-toggle="addAuthor"]').on('click', (e) => {
        const btn = $(e.currentTarget)
        const fieldset = btn.parents('.form-fieldset')
        let name = btn.data('name')
        let url = btn.data('url')
        var count = fieldset.attr('data-count')

        $.ajax({
            url: url,
            method: 'put',
            data: {
                name: name,
                k: count,
            },
            success: (data) => {
                count++
                fieldset.append(data)
                fieldset.attr('data-count', count)
                mask.inputs()
            }
        })
    })

    $('[data-toggle="removeAuthor"]').on('click', (e) => {
        const btn = $(e.currentTarget)
        const fieldset = btn.parents('.form-fieldset')
        const group = fieldset.find('.form-group-author:last-child')
        var count = fieldset.attr('data-count')

        if(count > 1) {
            count--
            group.remove()
            fieldset.attr('data-count', count)
        }
    })

})
