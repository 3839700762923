// JQuery
const $ = require('jquery');

// JQuery Mask PLugin
require('jquery-mask-plugin')

// JQUERY MASK PLUGIN
var SPMaskBehavior = function (val) {
    return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
},

spOptions = {
    onKeyPress: function(val, e, field, options) {
        field.mask(SPMaskBehavior.apply({}, arguments), options);
    }
};

var docMaskBehavior = function (val) {
    return val.replace(/\D/g, '').length === 14 ? '00.000.000/0000-00' : '000.000.000-009999';
},

docOptions = {
    onKeyPress: function(val, e, field, options) {
        field.mask(docMaskBehavior.apply({}, arguments), options);
    }
};

function validateString(string) {
    return string.replace(/ /g, '-')
        .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        .replace(/[<|,|>|\.|\?|\/|:|;|"|'|{|\[|}|\]|\||\\|~|`|!|@|#|\$|%|\^|&|\*|\(|\)|_|\+|=]+/g, '')
        .replace(/\-\-+/g, '-')
        .replace(/(^-+|-+$)/, '')
        .toLowerCase()
}

module.exports = {
    inputs: () => {
        $('.mask-phone').mask(SPMaskBehavior, spOptions)
        $('.mask-doc').mask(docMaskBehavior, docOptions)
        $('.mask-cep').mask('00000-000')
        $('.mask-date').mask('00/00/0000')
        $('.mask-datetime').mask('00/00/0000 00:00')
        $('.mask-cpf').mask('000.000.000-00')
        $('.mask-card').mask('0000 0000 0000 0000')
        $('.mask-validity').mask('00/0099')
        $('.mask-cvv').mask('000')
        $('.mask-money').mask('000.000.000.000.000,00', {
            reverse: true
        })
        $('.mask-sanitize').on('keyup', function() {
            $('.mask-sanitize-target').val(validateString($(this).val()))
        })
        $('.mask-card-name').on('keyup', function() {
            var val = $(this).val()
            $(this).val(val.toUpperCase())
        })
        $('.mask-card').on('keyup', function() {
            var cardnumber = $(this).val();

            cardnumber = cardnumber.replace(/[^0-9]+/g, '');

            var cards = {
                visa: /^4[0-9]{12}(?:[0-9]{3})/,
                mastercard: /^5[1-5][0-9]{14}/,
                diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
                amex: /^3[47][0-9]{13}/,
                discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
                elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
                hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
                jcb: /^(?:2131|1800|35\d{3})\d{11}/,
                aura: /^(5078\d{2})(\d{2})(\d{11})$/
            };

            for (var flag in cards) {
                if(cards[flag].test(cardnumber)) {
                    $(this).css('background-image', "url('/dist/images/card-"+ flag +".png')")
                }
            }
        })
    }
}
