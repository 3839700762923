const $ = require('jquery')
const bootstrap = require('bootstrap')

var unique = true
var thumbImage = ''
var thumbFile = ''
var inputHidden = ''

thumbImage+= '<figure class="figure img-thumbnail">'
    thumbImage+= '<img src="##PATH##" class="figure-img img-fluid" alt="">'
    thumbImage+= '<figcaption class="figure-caption">##NAME##</figcaption>'
    thumbImage+= '<buttom class="btn" data-toggle="remove">'
        thumbImage+= '<i class="fas fa-times"></i>'
        thumbImage+= '<span class="sr-only">Remover<span/>'
    thumbImage+= '</buttom>'
    thumbImage+= '##HIDDEN##'
thumbImage+= '</figure>'

thumbFile+= '<figure class="figure img-thumbnail">'
    thumbFile+= '<div class="figure-img img-fluid rounded">'
    thumbFile+= '<i class="fas fa-paperclip"></i></span>'
    thumbFile+= '</div><!--/.figure-img -->'
    thumbFile+= '<figcaption class="figure-caption">##NAME##</figcaption>'
    thumbFile+= '<buttom class="btn" data-toggle="remove">'
        thumbFile+= '<i class="fas fa-times"></i>'
        thumbFile+= '<span class="sr-only">Remover<span/>'
    thumbFile+= '</buttom>'
    thumbFile+= '##HIDDEN##'
thumbFile+= '</figure>'

inputHidden = '<input type="hidden" name="##TARGET##" value="##ID##">'

var media = {
    mediaModal: (loading) => {
        $('[data-toggle="media"]').on('click', function() {
            var route = $(this).attr('data-content')
            var target = $(this).attr('data-target')
            var multiple = $(this).attr('data-multiple')
            var modal = $('#mediaModal')

            $('#mediaModal .modal-body').html(loading)

            $.ajax({
                url: route,
                method: 'PUT',
                context: document.body
            }).done((data) => {
                $('#mediaModal .modal-body').html(data)
                media.selectMedia()
            })

            modal.attr('data-target', target).attr('data-multiple', multiple)

            var myModal = new bootstrap.Modal(document.getElementById('mediaModal'), {})
            myModal.show()

            $('#sendItem').attr('data-selected','[]')
            $('#sendItem').attr('data-target',target)
            $('#sendItem').attr('data-multiple',multiple)
        })

    },

    selectMedia: () => {
        var modal = $('#mediaModal')

        modal.find('[data-toggle="selectMedia"]').on('click', function(e) {
            e.stopPropagation()
            e.preventDefault()

            var multiple = modal.attr('data-multiple')
            var item = $(this)
            var target = $(modal.attr('data-target'))
            var selected = {
                'id': item.attr('data-id'),
                'type': item.attr('data-type'),
                'name': item.find('figcaption').html(),
                'path': item.find('img').attr('src')
            }
            var status = !item.hasClass('selected')
            var btn = item.find('[data-toggle="select"]')
            var send = $('#sendItem')
            var obj = send.attr('data-selected')

            obj = JSON.parse(obj)

            function contains(obj, list) {
                for (var i = 0; i < list.length; i++) {
                    if (list[i].id === obj.id) {
                        return i;
                    }
                }
            }

            if(status) {
                if(multiple == 0) {
                    item.parents('.e-list').find('.selected').removeClass('selected')
                        .find('[data-toggle="select"]').addClass('d-none')

                    obj = [selected]
                } else {
                    obj.push(selected)
                }

                btn.removeClass('d-none')
                item.addClass('selected')

                send.attr('data-selected', JSON.stringify(obj)).removeAttr('disabled')
            }

            else {
                btn.addClass('d-none')
                item.removeClass('selected')

                var i = contains(selected, obj)
                if(i !== false) {
                    obj.splice(i, 1)
                }

                send.attr('data-selected', JSON.stringify(obj))
                send.attr('data-target', JSON.stringify(obj))
                if(!obj.length) send.attr('disabled', 'disabled')
            }

        })
    },

    uploadToggle: (unique) => {
        $('[data-toggle="upload"]').on('click', function() {
            var target = $(this).attr('data-target')
            target = $(target)
            target.attr('type', 'file').removeAttr('disabled').trigger('click')

            if(unique) media.changeFile(target)
            unique = false
        });
    },

    changeFile: (input) => {
        if(unique) {
            input.on('change', function(e) {
                var html = '';
                var target = $(this).parents('.form-control-upload').find('.uploaded')
                var files = e.target.files
                var multiple = input.attr('multiple')

                if(!multiple) {
                    target.html('')
                    target.removeClass('active')
                }

                $.each(files,function(i, file) {

                    if(1 == 0
                        || file.type == 'image/jpeg'
                        || file.type == 'image/png'
                    ) {
                        var tmppath = URL.createObjectURL(file)

                        html = thumbImage.replaceAll('##NAME##', file.name)
                        html = html.replaceAll('##PATH##', tmppath)
                        html = html.replaceAll('##HIDDEN##', '')

                        target.addClass('active')
                    }

                    else {
                        html = thumbFile.replaceAll('##NAME##', file.name)
                        html = html.replaceAll('##HIDDEN##', '')

                        target.addClass('active')
                    }
                })

                target.append(html)
                media.removeButtom(target)
            })
        }
    },

    removeButtom: (container) => {
        container.find('[data-toggle="remove"]').on('click', function(e) {
            $(this).parent().remove()

            if(!container.find('figure').length) {
                container.removeClass('active')
                container.parent().find('input[type="file"]').val('')
            }
        })
    },

    sendItem: () => {
        $('#sendItem').on('click', function() {

            var html = ''
            var thumb = ''
            var hidden = ''
            var arrObj = JSON.parse($(this).attr('data-selected'))
            var target = $($(this).attr('data-target'))
            var multiple = $(this).attr('data-multiple')
            var element = target.replaceAll('#field_', '')
            target = $(target).find('.uploaded')

            $('[name*="'+ element +'"]').attr('disabled','disabled')

            if(multiple == 1) element+= '[]'

            arrObj.forEach((e, index, array) => {
                if(e.type.indexOf('image') == 0) thumb = thumbImage
                else thumb = thumbFile

                hidden = inputHidden.replaceAll('##TARGET##', element)
                hidden = hidden.replaceAll('##ID##', e.id)

                html+= thumb.replaceAll('##NAME##', e.name)
                if('path' in e) html = html.replaceAll('##PATH##', e.path)
                html = html.replaceAll('##HIDDEN##', hidden)
                html+= "\n"
            })

            if(multiple == 0) target.html(html)
            else target.append(html)

            target.addClass('active')
            media.removeButtom(target)
        })
    }
}

module.exports = media;
